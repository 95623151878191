import React, { Component, Suspense, lazy } from 'react';
import { Link, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { createBrowserHistory } from 'history';
import '../css/main.css';
const Home = lazy(() => import("../pages/home"));
const Nene = lazy(() => import("../pages/nene"));
const Backoffice = lazy(() => import("../pages/backoffice"));
const EmployeeLog = lazy(() => import("../pages/employeeLog"));
const Employee = lazy(() => import("../pages/employee"));
const FormPembelian = lazy(() => import("../pages/formPembelian"));
const FormPenjualan = lazy(() => import("../pages/formPenjualan"));
const FormStockOpname = lazy(() => import("../pages/formStockOpname"));

const history = createBrowserHistory()

const styles = theme => ({
  
});

class Master extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      windowWidth: window.innerWidth,
      version: window.innerWidth <= 800 ? "mobile" : "desktop",
      winScroll: 0
    };
  };

  componentDidMount() {
    // window.addEventListener('scroll', this.listenToScroll);
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.listenToScroll);
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    // This method control rendering between mobile vs desktop version
    // Default width benchmark = 800
    // If there's no changes between mobile to desktop and vice versa, skip the setState
    const previous_version = this.state.windowWidth <= 800 ? "mobile" : "desktop";
    const current_version = window.innerWidth <= 800 ? "mobile" : "desktop";
    
    if(previous_version !== current_version) {
      this.setState({
        windowWidth: window.innerWidth,
        version: current_version
      });
    }
  }

  render() {
    const { classes } = this.props;
    
    const App = () => (
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <Home {...props} version={this.state.version}/>}
          />
          <Route 
            path="/nene"
            render={(props) => <Nene {...props} version={this.state.version} />}
          />
          <Route 
            path="/backoffice"
            render={(props) => <Backoffice {...props} version={this.state.version} />}
          />
          <Route 
            path="/employeeLog"
            render={(props) => <EmployeeLog {...props} version={this.state.version} />}
          />
          <Route 
            path="/employee"
            render={(props) => <Employee {...props} version={this.state.version} />}
          />
          <Route 
            path="/pembelian"
            render={(props) => <FormPembelian {...props} version={this.state.version} />}
          />
          <Route 
            path="/penjualan"
            render={(props) => <FormPenjualan {...props} version={this.state.version} />}
          />
          <Route 
            path="/stockopname"
            render={(props) => <FormStockOpname {...props} version={this.state.version} />}
          />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Suspense>
    )

    return (
      <div style={{overflow: "hidden"}}>
        <App/>
      </div>
    );
  }
}

Master.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withRouter(
  withStyles(styles, { withTheme: true })(Master)
);
